<template>
    <div class="btn-group" role="group" @click="stopEvent">
        <button type="button" 
            class="btn btn-primary btn-sm" 
            :disabled="displayLevel <= 0"
            style="border-radius: 10px 00px 0px 10px;"
            :title="$t('Go up a level')"
            @click="expandToLevel(displayLevel-1)">
            <i class="bi bi-chevron-double-up"></i>
        </button>
        <TreeifyLevelsDropdown v-slot="scope" :dataObject="dataObject">
            <button type="button" 
                :ref="scope.target"
                class="btn btn-primary btn-sm"
                :title="$t('Set to level')"
                @click="expandToLevel(displayLevel)"
                @click.middle="scope.open()">
                {{displayLevel+1}}
            </button>
        </TreeifyLevelsDropdown>
        <button type="button" 
            class="btn btn-primary btn-sm"
            :disabled="displayLevel >= deepestLevel"
            style="border-radius: 0px 10px 10px 0px;"
            :title="$t('Go down a level')"
            @click="expandToLevel(displayLevel+1)">
            <i class="bi bi-chevron-double-down"></i>
        </button>
    </div>
</template>

<script setup lang="ts">
import type DataObject from 'o365.vue.ts';
import TreeifyLevelsDropdown from 'o365.vue.components.Treeify.LevelsDropdown.vue';
import { computed } from 'vue';

const props = defineProps<{
    dataObject: DataObject
}>();

const currentLevel = computed(() => props.dataObject.treeify?.currentLevel ?? 0);
const deepestLevel = computed(() => props.dataObject.treeify?.deepestLevel ?? 0);
const displayLevel = computed(() => Math.min(currentLevel.value, deepestLevel.value));

function expandToLevel(level) {
    props.dataObject.treeify.expandToLevel(level);
}

function stopEvent(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
}

</script>